import http from "../utils/http"


export function brandTypeList(params) {
    return http({
        url: "oloey/api/goods/brandTypeList",
        data: params
    })
}
export function goodsCategoryList(params) {
    return http({
        url: "oloey/api/goods/goodsCategoryList",
        data: params
    })
}
export function goodsNameList(params) {
    return http({
        url: "oloey/api/goods/getGoodsNameList",
        data: params
    })
}
export function goodsLists(params) {
    return http({
        url: "oloey/api/goods/lists",
        data: params
    })
}
export function AuthLetterCofig(params) {
    return http({
        url: "oloey/api/goods/getAuthLetterCofig",
        data: params
    })
}
export function orderCreate(params) {
    return http({
        url: "oloey/api/order/orderCreate",
        data: params
    })
}
export function orderStatus(params) {
    return http({
        url: "oloey/api/order/getOrderStatus",
        data: params
    })
}
export function orderList(params) {
    return http({
        url: "oloey/api/order/lists",
        data: params
    })
}
export function orderPay(params) {
    return http({
        url: "oloey/api/order/orderPay",
        data: params
    })
}
export function orderWechatPay(params) {
    return http({
        url: "oloey/api/order/orderWechatPay",
        data: params
    })
}
export function orderAliPay(params) {
    return http({
        url: "oloey/api/order/orderAliPay",
        data: params
    })
}
export function base64Qrcode(params) {
    return http({
        url: "oloey/api/Util/base64Qrcode",
        data: params
    })
}
export function getPayStatus(params) {
    return http({
        url: "oloey/api/order/getPayStatus",
        data: params
    })
}
export function orderDetail(params) {
    return http({
        url: "oloey/api/order/detail",
        data: params
    })
}
export function useTemplate(params) {
    return http({
        url: "oloey/api/order/useTemplate",
        data: params
    })
}
export function checkAuthCode(params) {
    return http({
        url: "oloey/api/order/checkAuthCode",
        data: params
    })
}
export function checkAntifakecode(params) {
    return http({
        url: "oloey/api/antifakecode/checkCode",
        data: params
    })
}
export function applyRefund(params) {
    return http({
        url: "oloey/api/order/applyRefund",
        data: params
    })
}
export function cancelRefund(params) {
    return http({
        url: "oloey/api/order/cancelRefund",
        data: params
    })
}
export function agreeRefund(params) {
    return http({
        url: "oloey/api/order/agreeRefund",
        data: params
    })
}
export function refuseRefund(params) {
    return http({
        url: "oloey/api/order/refuseRefund",
        data: params
    })
}
export function download(params) {
    return http({
        url: "oloey/api/app/download",
        data: params
    })
}
export function dateLimit(params) {
    return http({
        url: "oloey/api/goods/getDateLimit",
        data: params
    })
}

export function hasAuthLetter(params) {
    return http({
        url: "oloey/api/system/hasAuthLetter",
        data: params
    })
}

export function orderAuthLetterList(params) {
    return http({
        url: "oloey/api/system/getOrderAuthLetterList",
        data: params
    })
}

export function checkIsComplete(params) {
    return http({
        url: "oloey/api/system/checkGenerateComplete",
        data: params
    })
}
